import { createStore } from 'vuex';
import inclusaoStore from './inclusao';
import tarefaStore from './tarefas';
import tarefaDepositoStore from './tarefasDeposito';

const store = createStore({
    modules: {
        inclusao: inclusaoStore,
        tarefas: tarefaStore,
        tarefasDeposito: tarefaDepositoStore,
    },
    state() {
        return {
            todosProdutos: null,
            codigoInclusao: null,
            numeroSync: 0,
            tipoTarefa: '',
        };
    },
    mutations: {
        setTodosProdutos(state, payload) {
            state.todosProdutos = payload;
        },
        setCodigoInclusao(state, payload) {
            state.codigoInclusao = payload;
        },
        plusNumeroSync(state) {
            state.numeroSync++;
        },
        minusNumeroSync(state) {
            state.numeroSync--;
        },
        resetNumeroSync(state) {
            state.numeroSync = 0;
        },
        setTipoTarefa(state, payload) {
            state.tipoTarefa = payload;
        },
    },
    actions: {
        setTodosProdutos(context, payload) {
            context.commit('setTodosProdutos', payload);
        },
        setCodigoInclusao(context, payload) {
            context.commit('setCodigoInclusao', payload);
        },
        plusNumeroSync(context) {
            context.commit('plusNumeroSync');
        },
        minusNumeroSync(context) {
            context.commit('minusNumeroSync');
        },
        resetNumeroSync(context) {
            context.commit('resetNumeroSync');
        },
        setTipoTarefa(context, payload) {
            context.commit('setTipoTarefa', payload);
        },
    },
    getters: {
        getTodosProdutos(state) {
            return state.todosProdutos;
        },
        getNumerosSync(state) {
            return state.numeroSync;
        },
        getCodigoInclusao(state) {
            return state.codigoInclusao;
        },
        getTipoTarefa(state) {
            return state.tipoTarefa;
        },
    },
});

export default store;
