const tarefaDepositoStore = {
    state() {
        return {
            tarefasDeposito: '',
            categoriaDeposito: '',
            codigo_de_barras_deposito: '',
            validadeDeposito: '',
        };
    },
    mutations: {
        setTarefasDeposito(state, payload) {
            state.tarefasDeposito = payload;
        },
        setCategoriaDeposito(state, payload) {
            state.categoria = payload;
        },
        setCodigoDeposito(state, payload) {
            state.codigo_de_barras_deposito = payload;
        },
        setValidadeDeposito(state, payload) {
            state.validadeDeposito = payload;
        },
    },
    actions: {
        setTarefasDeposito(context, payload) {
            context.commit('setTarefasDeposito', payload);
        },
        setCategoriaDeposito(context, payload) {
            context.commit('setCategoriaDeposito', payload);
        },
        setCodigoDeposito(context, payload) {
            context.commit('setCodigoDeposito', payload);
        },
        setValidadeDeposito(context, payload) {
            context.commit('setValidadeDeposito', payload);
        },
    },
    getters: {
        getTarefasDeposito(state) {
            return state.tarefasDeposito;
        },
        getCategoriasDeposito(state) {
            let tarefas = state.tarefasDeposito;

            return tarefas.reduce(function (key, element) {
                key[element.categoria] = key[element.categoria] || [];
                key[element.categoria].push(element);
                return key;
            }, Object.create(null));
        },
        getTarefasCategoriaDeposito(state) {
            let prod = state.tarefasDeposito;

            let filtrados = prod.filter((element) => {
                return element.categoria == state.categoria;
            });

            return filtrados.reduce(function (key, element) {
                key[element.categoria] = key[element.categoria] || [];
                key[element.categoria].push(element);
                return key;
            }, Object.create(null));
        },
        getTotalTarefasDeposito(state) {
            return state.tarefasDeposito.length;
        },
        getCategoriaDeposito(state) {
            return state.categoria;
        },
        getTarefaDeposito(state) {
            let prod = state.tarefasDeposito;

            let filtrados = prod.filter((element) => {
                return (
                    element.codigo_de_barras == state.codigo_de_barras_deposito &&
                    element.data_validade == state.validadeDeposito
                );
            });
            return filtrados[0];
        },
    },
};

export default tarefaDepositoStore;
